<template>
  <div id="app-user-auction-container" class="auction-container pt-0">
    <div v-if="appLoading && auctions" :key="updatingKey">
      <div class="mobile-flashphoner" :class="{ safari: browser.name === 'safari' || browser.name === 'ios' || browser.os === 'iOS' }">
        <FlashPhoner
          v-if="isMobile && !showNetworkError && currentAuction.status === 'started'"
          type="mobile"
          :isFlashPhonerDisabled="isFlashPhonerDisabled" />
      </div>
      <v-row no-gutters class="auction-wrapper" v-if="ongoingArticle" :class="{ safari: browser.name === 'safari' || browser.name === 'ios' }">
        <articles-list-desktop
          v-if="(!isMobile || showArticlesList) && !showNetworkError && currentAuctionArticles.length && currentAuction.status && (currentAuction.status === 'started' || currentAuction.status === 'paused')"
          :isArticleSoldOut="isArticleSoldOut"
          :bidder-numbers=[]
        />
        <v-col class="auction-wrapper__content">
          <v-row no-gutters>
            <!-- Mobile Version -->
            <v-col cols="12" class="article-wrapper mobile-view" style="height: 100%" sm="12" xs="12">
              <v-row class="title-wrapper" no-gutters>
                <v-col cols="12" lg="12" md="12" sm="12" xs="12">
                  <div class="title">{{ articleTitle }}</div>
                </v-col>
                <v-row>
                  <v-col class="price-wrapper no-gutters">
                    <span class="light-title">{{ $t('Article') }}</span>&nbsp;
                    <span class="price-value">#{{ getArticleNumber }}</span>
                  </v-col>
                  <v-col class="price-wrapper no-gutters" v-if="ongoingArticle.limit">
                    <span class="light-title">{{ $t('Limit') }}</span>&nbsp;
                    <span class="price-value">
                      {{ currency }}{{ euNumbers(ongoingArticle.limit) }}
                    </span>
                  </v-col>
                  <v-col class="price-wrapper no-gutters mr-0" v-if="ongoingArticle.estimated_price">
                    <span class="light-title">{{ $t('Estimated price') }}</span>&nbsp;
                    <span class="price-value">
                      {{ currency }}{{ ongoingArticle.estimated_price }}
                    </span>
                  </v-col>
                  <v-col class="price-wrapper no-gutters" v-if="!isMobile && currentHighestBid && currentHighestBid.value">
                    <span class="light-title">{{ $t('Hammer price') }}</span>&nbsp;
                    <span class="price-value hammer-price">
                      {{ currency }}{{ euNumbers(currentHighestBid.value) }}
                    </span>
                  </v-col>
                  <div class="extra-spacer" v-if="!ongoingArticle.estimated_price || !ongoingArticle.estimated_price" />
                </v-row>
              </v-row>
            </v-col>
            <v-col class="image-slider-wrapper" cols="12" style="height: 100%" lg="6" md="6" sm="12" xs="12">
              <VueSlickCarousel lazyLoad="ondemand" :arrows="true" :key="articleGallery ? articleGallery.length : 0" :dots="false" class="slider-container" v-if="ongoingArticle && articleGallery && articleGallery.length">
                <template #prevArrow>
                  <div class="custom-arrow">
                    <v-icon class="arrow-left">fa-angle-left</v-icon>
                  </div>
                </template>

                <template #nextArrow>
                  <div class="custom-arrow">
                    <v-icon class="arrow-right">fa-angle-right</v-icon>
                  </div>
                </template>
                <div class="image-item" v-for="itm in articleGallery" :key="itm.articleNumber">
                  <v-img class="article-image" :contain="true" :src="itm.image" height="35vh" width="80%" />
                </div>
              </VueSlickCarousel>
              <div class="flashphoner-container-desk">
                <div class="slider-flashphoner" v-if="!isFlashPhonerDisabled && currentAuction.status === 'started'">
                  <FlashPhoner v-if="!isMobile" />
                </div>
              </div>
            </v-col>

            <v-row class="article-description mobile-view" no-gutters>
              <div>
                <span v-if="useHtml" class="useHtmlData" v-html="articleDescription"></span>
                <span v-else>{{ articleDescription }}</span>
              </div>
            </v-row>

            <v-col style="height: 100%;" class="article-wrapper" lg="6" md="6" sm="12" xs="12">
              <v-row class="title-wrapper" no-gutters>
                <v-col lg="12" md="12" sm="12" xs="12">
                  <div class="title">{{ articleTitle }}</div>
                </v-col>
                <v-row class="justify-start my-0">
                  <v-col class="price-wrapper no-gutters">
                    <span class="light-title">{{ $t('Article') }}</span>&nbsp;
                    <span class="price-value">#{{ ongoingArticle.number_optional || _.padStart(ongoingArticle.number, 4, '0') }}</span>
                  </v-col>
                  <br v-if="ongoingArticle.estimated_price">
                  <v-col class="price-wrapper no-gutters" v-if="ongoingArticle.limit">
                    <span class="light-title">{{ $t('Limit') }}</span>&nbsp;
                    <span class="price-value">{{ currency }}{{ euNumbers(ongoingArticle.limit) }}</span>
                  </v-col>
                  <br v-if="ongoingArticle.limit">
                  <v-col class="price-wrapper no-gutters" v-if="ongoingArticle.estimated_price">
                    <span class="light-title">{{ $t('Estimated price') }}</span>&nbsp;
                    <span class="price-value">{{ currency }}{{ ongoingArticle.estimated_price }}</span>
                  </v-col>
                </v-row>
              </v-row>

              <v-row class="pb-6" justify="start" no-gutters align="start">
                <v-col md="6" class="current-highest-bid-block border-right" v-if="ongoingArticle.status === '2' || ongoingArticle.status === '0'" style="min-height: 128px;">
                  <p class="mb-1 current-highest">{{ $t('Current highest bid') }}</p>
                  <p class="bold-500 bid-value mb-2">{{ currency }}{{ euNumbers(currentHighestBid.value) }}</p>
                  <p v-if="isArticleSoldOut && !isMobile" class="push-text mb-0 mt-4 blue-bg">
                    <span>{{ $t('This item is sold out') }}</span>
                  </p>
                  <p v-if="isLastArticleSoldOut && isArticleSoldOut" class="bold-500  sold-out-last">
                    <span>{{ $t('Last article sold out. The auction will end immediately.') }}</span>
                  </p>
                </v-col>
                <v-col md="6" v-if="ongoingArticle.status === '2' || ongoingArticle.status === '0' " class="pl-3 current-highest-bid-block notifications-block">
                  <div v-if="!isArticleSoldOut && showPushNotiTooltip && ongoingArticle.status === '2'" class="push-text mb-3" style="font-size: 16px;text-align:left;">
                    <div>{{ $t('The article is about to be sold.') }}</div>
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="mt-4 text-align-right">
                    <v-btn
                      :class="`new-bid-btn ${$i18n.locale}`"
                      @click="openLogin"
                      >
                      <v-icon class="add-new-icon">fa-sign-in-alt</v-icon>
                      <span>{{ $t('Login to bid') }}</span>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>

              <v-row class="article-description" no-gutters>
                <div>
                  <h3 class="details-title">{{ $t('Details') }}</h3>
                  <div class="overflow-auto" :style="{ maxHeight: descriptionMaxHeight }">
                    <span v-if="useHtml" class="useHtmlData" v-html="articleDescription"></span>
                    <span v-else>{{ articleDescription }}</span>
                  </div>
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!--    Start bottom navigation bar-->
      <v-row v-if="showFooter && !showNetworkError && !showArticlesList" class="bottom-navigation-bar">
        <v-row class="ma-0 do-bid-block full-width">
          <v-col cols="6" class="pa-0">
            <v-row class="align-center fill-height ma-0 bidder-status-message-block">
              <v-col class="pa-1">
                <p class="mb-0" v-if="currentHighestBid && currentHighestBid.value && userProfile.id !== currentHighestBid.id && !isArticleSoldOut">
                  <span class="light-title">{{ $t('Hammer price') }}</span>&nbsp;
                  <strong>
                    {{ currency }}{{ euNumbers(currentHighestBid.value) }}
                  </strong>
                </p>
                <p v-if="isArticleSoldOut && !isMobile" class="push-text mb-0 mt-4 blue-bg">
                  <span>{{ $t('This item is sold out') }}</span>
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" class="pa-0">
            <v-btn
              class="new-bid-btn mobile"
              text
              @click="openLogin"
            >
              <span>{{ $t('Login') }}</span>
            </v-btn>
            <v-tooltip v-model="showPushNotiTooltip" :content-class="`show-on-top hide-desktop mobile-operation-noti  ${(showPushNotiTooltip && ongoingArticle.status === '2') ? 'black-bg' : ''}`" nudge-top="20" top allow-overflow internal-activator v-if="ongoingArticle.status === '2' && userProfile.id !== currentHighestBid.id">
              <template v-slot:activator="{ on, attrs }">
                <span class="mobile-notification-cap"></span>
              </template>
              <span v-if="!isArticleSoldOut" class="push-text">
                <v-icon class="warning-icon">fa-triangle-exclamation</v-icon>
                <span style="padding-left:5px">{{ $t('The article is about to be sold.') }}</span>
              </span>
              <span v-if="ongoingArticle.status === '1'" class="push-text">
                <v-icon class="hourglass-icon rotation-animation" style="font-size: 20px">hourglass_empty</v-icon>{{ $t('Waiting for bidding to start...') }}
              </span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-row>
      <!--    Stop bottom navigation bar-->

      <!--    Start footer crawler-->
      <!-- <articles-overview-desktop
          @onClickArticle="selectArticle"
          v-if="renderComponent && currentAuctionArticles.length"
      /> -->

      <!-- Todo : We need to change this to component -->
      <v-overlay class="custom-back-drop status-modal" :class="{ 'paused-modal': currentAuction.status === 'paused' }" v-if="renderComponent && loadingModal">
        <div class="message-box-wrapper">
          <div class="message-box">
            <div class="message-content">
              <span class="loading-message">{{ getLoadingMessage() }}</span>
            </div>
          </div>
        </div>
      </v-overlay>

      <v-overlay class="custom-back-drop status-modal" v-if="showNetworkError">
        <div class="message-box-wrapper">
          <div class="message-box">
            <div class="message-content">
              <v-progress-circular
                class="loading-icon"
                indeterminate
                color="#555"
              />
              <br>
              <span class="loading-message">{{ getLoadingMessage() }}</span>
            </div>
          </div>
        </div>
      </v-overlay>

      <v-overlay class="custom-back-drop no-ongoing-article-modal" v-if="currentAuction.status === 'started' && _.isEmpty(ongoingArticle)">
        <div class="message-box-wrapper">
          <div class="message-box">
            <div class="message-content">
              <v-progress-circular
                class="loading-icon"
                indeterminate
                color="#555"
              />
              <br>
              <span class="loading-message">{{ $t('No ongoing article found in the live auction. Please wait') }}</span>
            </div>
          </div>
        </div>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import SocketClient from '@/utils/socket'
import { mapActions, mapState } from 'pinia'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import ArticlesListDesktop from '../auction/articles-overview/articles-list-desktop'
import AuctionsOverview from '@/views/dashboard-user/index'
import FlashPhoner from '@/components/flashphoner/index'
import apiRequests from '@/api/apiRequests'
import SocketHandlers, { SocketPostHandleEmitterEvent } from '@/api/socketHandlers';
import { detect } from 'detect-browser';
import useRootStore from '@/stores/rootStore';
import useAuctionStore from '@/stores/auctionStore';
import { BidType, localFeatures, SocketConnectionStatus, SocketNamespace } from '@/utils/constants';
import { euNumbers, getTranslatedText } from '@/services/i18n';

export default {
  name: 'AuctionGuest',
  components: {
    VueSlickCarousel,
    ArticlesListDesktop,
    FlashPhoner,
    AuctionsOverview
  },
  async created () {
    // don't show the article slider in the mobile view
    if (this.isMobile) {
      useRootStore().showArticlesList = false;
    }
  },
  async mounted() {

    try {
      // Listener
      this.setUpSocket()

      this.UPDATE_GLOBAL_STATE({
        key: 'appLoading',
        value: false,
      })
      await this.getAuctions()

      let rs = await this.getCurrentActiveAuction()
      // Cant connect to server
      //TODO exception is thrown on error, check this in auction view as well
      if (!rs || !rs.data) {
        this.CHANGE_STATE([
          {
            key: 'loadingModal',
            value: true,
          },
          {
            key: 'loadingType',
            value: 'noLive',
          }])
      }
      // Hide modal if auction is available
      if (rs.data) {
        // Get article data for slider
        console.log('get articles for active auction, auction state is', rs.data.status)
        if (this.currentAuction && this.currentAuction.id) await this.getAuctionArticles({
          auctionId: this.currentAuction.id,
          mediaServer: this.appLocalization.media_server_url,
          auctionCode: this.currentAuction.code,
          lang: this.appStoreLang,
          isUserFE: true
        })

        if (rs.data.status === 'paused') {
          if (this.showNetworkError) {
            this.CHANGE_STATE([
              {
                key: 'loadingModal',
                value: false,
              },
              {
                key: 'loadingType',
                value: 'networkPause',
              }])
          } else {
            this.CHANGE_STATE([
              {
                key: 'loadingModal',
                value: true,
              },
              {
                key: 'loadingType',
                value: 'paused',
              }])
          }
        }

        if (rs.data.status === 'started') {
          this.CHANGE_STATE([
            {
              key: 'loadingModal',
              value: false,
            },
            {
              key: 'loadingType',
              value: 'started',
            }
          ])
        }

        // console.log("rs.data.status",rs.data.status)
      }

      // Enable Flashphoner

      this.isFlashPhonerDisabled = this.appSettings.url_stream_type === 'stream_disabled'
      this.UPDATE_GLOBAL_STATE({
        key: 'appLoading',
        value: true,
      })

      // show the auction join modal if the user logged in and is on the dashboard
      // if (!_.isEmpty(this.userProfile)) await this.showJoinModal()
    } catch (e) {
      // console.log(e)
      this.UPDATE_GLOBAL_STATE([
        {
          key: 'error',
          value: true,
        },
        {
          key: 'errorMessage',
          value: e.message,
        },
      ])
      this.UPDATE_GLOBAL_STATE({
        key: 'appLoading',
        value: true,
      })
    }
  },
  beforeDestroy() {
    const emitter = SocketHandlers.getEmitter();
    emitter.off(SocketPostHandleEmitterEvent.startAuctionPostHandleEmitterEvent, this.startAuctionPostHandleEmitterEvent);
    emitter.off(SocketPostHandleEmitterEvent.resumeAuctionPostHandleEmitterEvent, this.resumeAuctionPostHandleEmitterEvent);
    emitter.off(SocketPostHandleEmitterEvent.pushDataUpdatePostHandleEmitterEvent, this.pushDataUpdatePostHandleEmitterEvent);
    emitter.off(SocketPostHandleEmitterEvent.stopAuctionPreHandleEmitterEvent, this.stopOrEndAuctionPreHandleEmitterEvent);
    emitter.off(SocketPostHandleEmitterEvent.endAuctionPreHandleEmitterEvent, this.stopOrEndAuctionPreHandleEmitterEvent);
    emitter.off(SocketPostHandleEmitterEvent.changeArticlePreHandleEmitterEvent, this.changeArticlePreHandleEmitterEvent);
    emitter.off(SocketPostHandleEmitterEvent.changeArticlePostHandleEmitterEvent, this.changeArticlePostHandleEmitterEvent);
    emitter.off(SocketPostHandleEmitterEvent.sellItemPostHandleEmitterEvent, this.sellItemPostHandleEmitterEvent);
  },
  async destroyed() {
    if (this.socket.disconnect) {
      this.socket.disconnect()
    }
    SocketClient.removeInstance()
  },
  data: () => ({
    slideModel: null,
    items: [],
    showNotiTooltip: false, // When user got higher bid
    notificationMessage: '',
    currentMobilePage: 1,
    showMobileArticleModal: false,
    articleGallery: [],
    showNetworkError: false,
    isArticleSoldOut: false,
    isLastArticleSoldOut: false,
    connectedTime: 0,
    disconnectedTime: 0,
    connectionTimer: null,
    disconnectionTimer: null,
    disconnected: false,
    cachedCurrentArticle: null,
    renderComponent: true,
    showPushNotiTooltipTest: true,
    isFlashPhonerDisabled: false,
    auctions: null,
    lastAuctionData: null,
    rcTimer: 0,
    soundUrl: require('@/assets/sold-audio.mp3'),
    updatingKey: 0,
    getStatusLink: null,
    overviewKey: 0,
    browser: detect(),
    bidSent: false,
    useHtml: localFeatures.useHtml,
    descriptionMaxHeight: '300px',
  }),
  watch: {
    async ongoingArticle() {
      if (this.currentAuction && this.currentAuction.id) {
        if (this.ongoingArticle.id !== this.cachedCurrentArticle) {
          this.cachedCurrentArticle = this.ongoingArticle.id
          this.articleGallery = this.ongoingArticle.images
        }
      }
    },
    currentAuction: {
      deep: true,
      handler() {
        this.isArticleSoldOut = (this.currentAuction && this.ongoingArticle && this.ongoingArticle.status === '0');
      }
    },
    async isOffline(newVal, oldVal) {
      if (!oldVal && newVal) {
        this.showNetworkError = true
      } else {
        this.showNetworkError = false
        await this.getCurrentActiveAuction()
      }
      //this.$forceUpdate()
    },
    async socketConnectionStatus() {
      console.log('socket status in auction guest view:', this.socketConnectionStatus)
      if (this.socketConnectionStatus === SocketConnectionStatus.connected) {
        this.connectedTime = 0
        this.disconnectedTime = 0
        clearTimeout(this.connectionTimer)
        clearTimeout(this.disconnectionTimer)
        this.connectedTimer()
        this.disconnected = false
      } else {
        // Some kind of disconnect status
        if (!this.disconnected) {
          this.connectedTime = 0
          clearTimeout(this.connectionTimer)
          this.disconnectedTimer()
          this.disconnected = true
        }
      }
    },
    productCataloguePredefinedValue(val) {
      if (!val) this.forceRerender()
    }
  },
  computed: {
    ...mapState(useAuctionStore, ['currentAuction', 'loadingModal', 'loadingType', 'showPushNotiTooltip', 'currentAuctionArticles', 'joinModal', 'isAdminLogout']),
    ...mapState(useRootStore, ['showArticlesList', 'userProfile', 'notificationList', 'showBottomNavigation', 'appLoading', 'productCataloguePredefinedValue', 'isLogoutClicked', 'appStoreLang', 'appSettings', 'appLocalization', 'appFeatures', 'isMobile', 'isOffline', 'socket', 'socketConnectionStatus']),
    ...mapState(useAuctionStore, ['ongoingArticle', 'currentHighestBid', 'nextBidAmount', 'currentStepValue']),
    BidType() {
      return BidType
    },
    articleTitle () {
      return getTranslatedText(this.ongoingArticle, 'name', this.appStoreLang)
    },
    articleDescription () {
      return getTranslatedText(this.ongoingArticle, 'description', this.appStoreLang)
    },
    showFooter () {
      return this.isMobile && this.loadingType !== '' && this.loadingType !== 'connection' && this.loadingType !== 'paused' && this.loadingType !== 'noLive' && this.loadingType !== 'endAuction'
    },
    currency () {
      return this.appLocalization.currency
    },
    getArticleNumber() {
      return this.ongoingArticle.number_optional || this.ongoingArticle.number
    }
  },
  methods: {
    ...mapActions(useAuctionStore, ['CHANGE_STATE', 'getCurrentActiveAuction', 'doBid', 'doSocketBid', 'getAuctionArticles', 'SET_IS_ADMIN_LOGOUT']),
    ...mapActions(useRootStore, ['UPDATE_GLOBAL_STATE', 'SET_TEMP_ALERT', 'UPDATE_AUCTIONS_FETCHED_ARTICLE', 'REMOVE_AUCTIONS_FETCHED_ARTICLE', 'ADD_AUCTIONS_FETCHED_ARTICLE', 'getNotification', 'markReadNotification', 'logout', 'SET_SOCKET_DATA']),
    async getAuctions() {
      try {
        let data
        const resp = await apiRequests.getGuestAuctions()
        data = resp.data
        this.auctions = data.data
      } catch (e) {
        this.error = true;
      }
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;
      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true;
      });
    },
    connectedTimer() {
      this.connectionTimer = setTimeout(() => {
        this.connectedTime += 1
        this.connectedTimer()
      }, 1000)
    },
    disconnectedTimer() {
      this.disconnectionTimer = setTimeout(() => {
        this.disconnectedTime += 1
        this.disconnectedTimer()
      }, 1000)
    },
    euNumbers,
    // Listen event from socket server
    async startAuctionPostHandleEmitterEvent(data) {
      this.SET_IS_ADMIN_LOGOUT(false)
      // await this.getCurrentActiveAuction()
      // Store
      this.CHANGE_STATE({
        key: 'currentAuction',
        value: data,
      })

      await this.getAuctionArticles({
          auctionId: this.currentAuction.id,
          mediaServer: this.appLocalization.media_server_url,
          auctionCode: this.currentAuction.code,
          lang: this.appStoreLang,
          isUserFE: true
      });
      this.articleGallery = this.ongoingArticle.images
      this.CHANGE_STATE(
        [
          {
            key: 'loadingModal',
            value: false,
          },
          {
            key: 'loadingType',
            value: 'started',
          }
      ])
    },
    async resumeAuctionPostHandleEmitterEvent(data) {
      this.SET_IS_ADMIN_LOGOUT(false)
      this.CHANGE_STATE([{
          key: 'loadingModal',
          value: false,
        },
        {
          key: 'loadingType',
          value: 'resumed',
        }
      ])
    },
    async stopOrEndAuctionPreHandleEmitterEvent(data) {
      if (_.isEmpty(this.currentAuction) && data === null) {
        this.auctions.forEach(auction => {
          if (auction.status === 'started') {
            auction.status = 'stopped'
          }
        })
      }
    },
    async pushDataUpdatePostHandleEmitterEvent(data) {
      console.log('auction/index.vue')
      console.log(`received real-time data update, user: ${data.updated_by}, entity: ${data.entity}, type: ${data.type}`)
      console.log('payload:', (data.data))
      if (data.entity === 'message' && this.appFeatures.webSocketDataPush) {
        this.SET_TEMP_ALERT(data.data)
      }
      if (this.appFeatures.webSocketDataPush) {
        console.log('processing')
        if (data.entity === 'article') {
          if (!JSON.parse(localStorage.getItem(`${data.data.auction_id}ArticleCache`))) return
          const cachedData = [...JSON.parse(localStorage.getItem(`${data.data.auction_id}ArticleCache`))]
          if (cachedData) {
            if (data.type === 'update') {
              const updatedData = cachedData.map(article => {
                if (article.id === data.data.id) {
                  return {
                    ...article,
                    ...data.data
                  }
                }
                return article
              })
              this.UPDATE_AUCTIONS_FETCHED_ARTICLE({
                auctionID: data.data.auction_id,
                article: data.data
              })
              localStorage.setItem(`${data.data.auction_id}ArticleCache`, JSON.stringify(updatedData))
            } else if (data.type === 'delete') {
              const idx = cachedData.findIndex(el => el.id === data.data.id)
              cachedData.splice(idx, 1)
              this.REMOVE_AUCTIONS_FETCHED_ARTICLE({
                auctionID: data.data.auction_id,
                article: data.data
              })
              localStorage.setItem(`${data.data.auction_id}ArticleCache`, JSON.stringify(cachedData))
            } else if (data.type === 'create') {
              cachedData.push(data.data)
              this.ADD_AUCTIONS_FETCHED_ARTICLE({
                auctionID: data.data.auction_id,
                article: data.data
              })
              localStorage.setItem(`${data.data.auction_id}ArticleCache`, JSON.stringify(cachedData))
            }
          }
        }
      }
    },
    async changeArticlePreHandleEmitterEvent(data) {
      this.CHANGE_STATE({
        key: 'showPushNotiTooltip',
        value: false,
      })
      this.$toast.clear()
    },
    async changeArticlePostHandleEmitterEvent(data) {
      this.forceRerender()
      // commit('UPDATE_ARTICLE_STATUS', this.ongoingArticle)
      this.CHANGE_STATE({
        key: 'loadingModal',
        value: false,
      })
    },
    async sellItemPostHandleEmitterEvent(data) {
      //TODO broken
      const idx = this.currentAuctionArticles.findIndex(el => el.id === data.id)
      const imageData = this.currentAuctionArticles[idx].image
      this.$set(this.currentAuctionArticles, idx, { ...data, image: imageData, bidder_id: this.currentHighestBid.id, live_bidder: this.currentAuction.highestBid.type === 'live' })
      this.playSound()
      this.isArticleSoldOut = true
      if (this.isMobile) {
          this.$toast.info(this.$t('This item is sold out'), {
            position: 'top',
            duration: 0
          })
        }
      // await this.getAuctionArticles(this.currentAuction.id)
      this.forceRerender()
    },
    setUpSocket() {
      this.UPDATE_GLOBAL_STATE({
        key: 'appLoading',
        value: false,
      })
      const { socket } = SocketClient.getInstance(SocketNamespace.guests);
      const emitter = SocketHandlers.getEmitter();

      emitter.on(SocketPostHandleEmitterEvent.startAuctionPostHandleEmitterEvent, this.startAuctionPostHandleEmitterEvent);
      emitter.on(SocketPostHandleEmitterEvent.resumeAuctionPostHandleEmitterEvent, this.resumeAuctionPostHandleEmitterEvent);
      emitter.on(SocketPostHandleEmitterEvent.stopAuctionPreHandleEmitterEvent, this.stopOrEndAuctionPreHandleEmitterEvent);
      emitter.on(SocketPostHandleEmitterEvent.endAuctionPreHandleEmitterEvent, this.stopOrEndAuctionPreHandleEmitterEvent);
      emitter.on(SocketPostHandleEmitterEvent.pushDataUpdatePostHandleEmitterEvent, this.pushDataUpdatePostHandleEmitterEvent);
      emitter.on(SocketPostHandleEmitterEvent.changeArticlePreHandleEmitterEvent, this.changeArticlePreHandleEmitterEvent);
      emitter.on(SocketPostHandleEmitterEvent.changeArticlePostHandleEmitterEvent, this.changeArticlePostHandleEmitterEvent);
      emitter.on(SocketPostHandleEmitterEvent.sellItemPostHandleEmitterEvent, this.sellItemPostHandleEmitterEvent);

      this.SET_SOCKET_DATA(socket)
    },
    getLoadingMessage() {
      if (this.showNetworkError) {
        return this.$t('auctionLoadingState.userNetworkIssue')
      }
      return this.$t('auctionLoadingState.' + this.loadingType)
    },
    playSound() {
      const audio = new Audio(this.soundUrl);
      audio.play();
    },
    openLogin() {
      this.$router.push({ name: 'login' })
    },
  },
}
</script>
<style lang="scss" scoped>
.auction-wrapper {
  position: relative;
  padding-top: 10px;
  &__content {
    @media screen and (min-width: 968px) {
      max-width: calc(100% - 332px);
    }
    @media screen and (min-width: 968px) and (max-width: 1300px) {
      padding-left: 340px !important;
      max-width: 100%;
    }
  }
  &.safari {
    @media(max-width: 968px) {
      padding-top: 80px;
    }
  }
}
.info-text {
  display: flex;
  align-items: center;
  text-align: left;
  max-width: 100%;
  margin: 0 auto 15px;
  background: #87A4CF;
  padding: 11px 24px 11px 19px;
  color: #264D86;
  border-radius: 8px;
  @media(max-width: 760px) {
    display: none;
  }
  .v-icon {
    font-size: 27px;
    color: #264D86;
  }
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.02em;
    margin: 0 0 0 15px;
  }
}
.mobile-notification-cap {
  position: relative;
  top: -35px;
  right: -30px;
}
</style>
